import localeDa from '@angular/common/locales/da';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';

import { NgxMatTimepickerModule } from '@alexfriesen/ngx-mat-timepicker';
import { DatePipe, registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DateTimePipe, initializeFontAwesomeFactory } from '@f2020/shared';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import 'firebase/firestore';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { Settings } from 'luxon';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

const materialModule = [
  MatSidenavModule,
  MatIconModule,
  MatToolbarModule,
  MatSnackBarModule,
  MatButtonModule,
];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      const db = getFirestore();
      if (environment.useEmulator) {
        connectFirestoreEmulator(db, 'localhost', 8080);
        console.warn('Using firestore emulator');
        connectAuthEmulator(getAuth(), 'http://localhost:9099');
        console.warn('Using auth emulator');
      }
      return db;
    }),
    provideFunctions(() => {
      const functions = getFunctions(undefined, 'europe-west1');
      if (environment.useEmulator) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
        console.warn('Using functions emulator');
      }
      return functions;
    }),
    provideMessaging(() => getMessaging()),
    importProvidersFrom(
      BrowserModule,
      ServiceWorkerModule,
      GoogleMapsModule,
      materialModule,
      FontAwesomeModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      NgxMatTimepickerModule.setLocale('da-DK'),
    ),
    {
      provide: LOCALE_ID,
      useValue: 'da',
    },
    DatePipe,
    DateTimePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFontAwesomeFactory,
      deps: [FaIconLibrary],
      multi: true,
    },
    provideAnimations(),
  ],
}).then(async () => {
  if ('serviceWorker' in navigator && environment.production) {
    await navigator.serviceWorker.register('ngsw-worker.js');
  }
  Settings.defaultLocale = 'da';
  registerLocaleData(localeDa);

}).catch(err => console.log(err));
