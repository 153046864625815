export const environment = {
  production: true,
  useEmulator: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAK9_kQTf7Lm7TdbYqDP_nFo7fwWvuHfLg',
    authDomain: 'f1.bregnvig.dk',
    projectId: 'f1-2024-8ab8e',
    storageBucket: 'f1-2024-8ab8e.appspot.com',
    messagingSenderId: '651727325900',
    appId: '1:651727325900:web:aec3360693b0b39516d069',
    vapidKey: 'BFnBh8HKN7mhvwA-auVC54sAjaHFBR2gJ6GxtwdW5LxeFfOGztsW84VMlqP9Szzpkhq2MBCA1NXh53BLaUNFc4c',
  },
};
