<div class="min-h-screen flex flex-col">
  <mat-toolbar class="flex flex-row justify-between sticky top-0 z-10">
    <a class="font-bold no-underline" routerLink="/2024">F2024</a>
    <!-- This fills the remaining space of the current row -->
    <button mat-icon-button (click)="sidenav.toggle()">
      <fa-icon [icon]="bars"></fa-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav-container class="w-full flex-auto mx-auto">
    <mat-sidenav #sidenav mode="over" position="end" [fixedInViewport]="true">
      <sha-sidebar (closing)="sidenav.close()"></sha-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="flex-auto flex flex-col justify-center justify-items-center">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
